<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="settingCollection"
          :search="search"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
          :footer-props="{
            'items-per-page-options': [100],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <DefaultSettingModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></DefaultSettingModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('update')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
import ApiService from "@/core/services/api.service";

import DefaultSettingModalForm, {
  initialFormData,
} from "./DefaultSettingModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "system.setting.";

export default {
  name: "DefaultSettings",
  components: { DefaultSettingModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.DEFAULT_SETTINGS"),
      search: "",
      routePath: "/settings/defaultSettings/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.code"),
          value: "code",
        },
        {
          text: this.$t("TABLE.value"),
          value: "value",
        },
        { text: this.$t("TABLE.actions"), value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters(["settingCollection", "statuses"]),

    endPoint() {
      return "setting/";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchSetting"]),

    editItem(id) {
      this.$nextTick(() => {
        this.modalData.editedId = id;
        this.modalData.dialog = true;
      });
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(this.routePath + item.code);
        this.editItem(item.code);
      }
    },

    handleDeleteItemConfirm() {
      ApiService.delete(this.endPoint + this.editedItem.code)
        .then(() => {
          this.fetchModel();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.handleCloseDelete();
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == this.permissionTo + action);
    },

    fetchModel() {
      return this.fetchSetting();
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
